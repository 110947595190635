'use client';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { Form as FormType } from '@/app/_types/components/form';
import { FilesMeta } from '@/app/_types/file';
import { saveFormData } from '@/app/actions';

import ButtonCustom from '../button/ButtonCustom';
import CloudflareTurnstile from '../cloudflare-turnstile/CloudflareTurnstile';
import FormInput from '../form/FormInput';
import ThankYou from '../thankyou/ThankYou';

import Styles from './ContactForm.module.scss';

type ContactFormProps = FormType & { filesMeta: FilesMeta };

const ContactForm = (props: ContactFormProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const toggleForm = () => setIsOpen(!isOpen);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isTurnstileLoading, setIsTurnstileLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const values = Form.useWatch([], form);
  const [messageApi, contextHolder] = message.useMessage();
  const pathname = usePathname();
  const saveData = saveFormData.bind(
    null,
    pathname,
    props.submission_key,
    props.id,
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const getTurnstileToken = (token: string) => {
    setIsTurnstileLoading(false);
    setTurnstileToken(token);
  };

  const handleSubmit = async (data: any): Promise<boolean> => {
    if (!turnstileToken && process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY) {
      messageApi.open({
        type: 'error',
        content: 'Please complete the verification.',
        duration: 5,
      });
      return false;
    }

    const response = await saveData({ ...data, turnstileToken });

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      return false;
    } else {
      form.resetFields();

      setSuccess(true);
      setTurnstileToken(null);
      return true;
    }
  };
  const thankYouData = {
    submittedTitle: props.submitted_title,
    submittedParagraph: props.submitted_paragraph,
    submittedImage: props.submitted_image,
  };

  return (
    <div className={Styles.contactFormWrapper}>
      {contextHolder}
      <button className={Styles.contactFormTitle} onClick={toggleForm}>
        {props.title} {isOpen ? <MinusOutlined /> : <PlusOutlined />}
      </button>
      {props.description && (
        <div
          className={Styles.contactFormDescription}
          dangerouslySetInnerHTML={{ __html: props.description }}
        ></div>
      )}
      {isOpen && (
        <>
          {!success ? (
            <Form
              className={Styles.contactForm}
              size="large"
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
            >
              <div className={Styles.contactFormField}>
                {props.form_inputs.map((input) => (
                  <FormInput key={input.id} input={input} form={form} />
                ))}
              </div>
              <div className={Styles.acceptTerms}>
                {props.terms_and_conditions}
              </div>
              <CloudflareTurnstile
                onSuccess={getTurnstileToken}
                onError={() => setIsTurnstileLoading(false)}
                onLoad={() => setIsTurnstileLoading(true)}
              />
              <div className="pr-mob-2 pr-6">
                <ButtonCustom
                  htmlType="submit"
                  fullWidth={true}
                  btnText={props.submit_text}
                  disabled={
                    !form.isFieldsTouched() ||
                    !isFormValid ||
                    isTurnstileLoading
                  }
                />
              </div>
            </Form>
          ) : (
            <ThankYou
              {...thankYouData}
              filesMeta={props.filesMeta}
              cardStyle={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ContactForm;
